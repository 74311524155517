<template>
    <Modal class="application-edit-form" v-if="opened" @close="closeForm()" :opened="opened">
        <template v-slot:header>
            {{ $t('controls.report_selection') }}
        </template>
        <template v-slot:body>
            <BaseButton @click="$emit('generate_pdf','generate-transcripts',true)">Opis vybraných žádostí</BaseButton>
            <BaseButton @click="$emit('generate_pdf','applications-list',true,{title: list_header})">Opis seznamu vybraných žádostí</BaseButton>
            <div class="row">
                <label for="dopln">doplňující záhlaví seznamu </label>
                <input id="dopln" name="dopln" type="text" v-model="list_header">
            </div>
            
            <BaseButton @click="$emit('generate_pdf','applications-accepted',true,{internal: 0})">Seznam rozhodnutí o přijetí - OFICIÁLNÍ</BaseButton>
            <BaseButton @click="$emit('generate_pdf','applications-accepted',true,{internal: 1})">Seznam rozhodnutí o přijetí - INTERNÍ (se jmény)</BaseButton>
            <BaseButton @click="$emit('generate_pdf','application-letters',true)">Rozhodnutí o přijetí (jen pro přijaté z aktuálního zařízení)</BaseButton>
            <BaseButton @click="exportXml()" class="export-xml">Export žádostí aktuálního zařízení do souboru zadosti.xml</BaseButton>
        </template>
    </Modal>
</template>

<script>
import Modal from "@common/components/Modal";
import { ref } from 'vue';
import { useStore } from 'vuex';
import config from 'config';
import { useRouter } from 'vue-router';
import { httpService } from "@common/services/http.service";

export default {
    name: "SelectReport",
    components: {
        Modal
    },
    emits: ['close','generate_pdf'],
    props: {
        opened: Boolean,
    },
    setup(props,context){     
        const closeForm = () => {
            context.emit('close');
        }
        const list_header = ref("");

        const store = useStore();
        const router = useRouter();
        const axiosConfig = {
            baseURL: config.apiUrl,
        }
        const httpClient = httpService(axiosConfig);
        const exportXml = () => {
            let url = router.currentRoute.value.params.lang+`/`+router.currentRoute.value.params.project_code+`/v1/enrollment/report/zapis-xml`;
            return httpClient.get(url,{ responseType: 'blob' })
                .then(response => {
                    // console.log(response.data);
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'zapis.xml'); 
                    document.body.appendChild(link);
                    link.click();
                },
                error => {
                    store.dispatch('alert/error', error);
                });
        }
        
        return {
            closeForm,
            list_header,
            exportXml
        }
    }
}
</script>

<style scoped>
.btn{
    display: block;
    margin: 0.5rem;
}
.row{
    margin: 0 1rem;
    font-size: 0.8rem;
    text-align: right;
}
.export-xml{
    margin-top: 2rem;
    font-size: 0.8rem;
}
</style>